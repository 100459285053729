import React from 'react';
import { StarIcon } from '@heroicons/react/outline'
import { StarIcon as SolidStarIcon } from '@heroicons/react/solid'

const TargetMarket = () => {
    return (<>
        <h1 className='text-2xl mb-4 tracking-wider text-center md:text-left underline underline-offset-2 decoration-double mb-10'>Sumo for all</h1>
        <div className='text-center leading-relaxed space-y-5'>
            <p>The Internet is full of content and finding good articles can be hard - Mindful Sumo aims to get you the best content faster.</p>
            <p>If you are the sort of person who wants to improve yourself and looking for an easy digestable weekly feed of the best articles around leadership, personal development, and mental health then our newsletter will be for you.</p>
            <p>Mindful Sumo is my newsletter that contains my favourite things that I have found and thought worth while sharing, think of it as a little positivity in your inbox each week.  The theme as you
                would expect is based around articles I have found that can help build your character and help strengthen your mental resilience. </p>

        </div>
        <div className='flex justify-center mt-20'>
            <StarIcon className='h-8 text-sumo-white' />
            <SolidStarIcon className='h-12 -mt-4 text-sumo-white' />
            <StarIcon className='h-8 text-sumo-white' />
        </div>
    </>);
}

export default TargetMarket;