import React from 'react';
import { ScaleIcon, ClockIcon, ShieldCheckIcon, ClipboardCheckIcon } from '@heroicons/react/solid'

const CoreValues = () => {
    return (<>
        <h1 className='text-2xl mb-4 tracking-wider text-center md:text-left underline underline-offset-2 decoration-double'>Our Core Values</h1>
        <div className='flex w-full justify-evenly flex-col md:flex-row'>

            <div className='pb-4 text-center'>
                <ScaleIcon className='h-8 text-sumo-red mx-auto' /> 
                <h2 className='text-2xl mt-1'>
                    Integrity
                </h2>
                <p className='mt-5 pr-4'>Do right by our readers and curators.</p>
            </div>
            <div className='pb-4 text-center'>
                <ClockIcon className='h-8 text-sumo-blue mx-auto' />
                <h2 className='text-2xl mt-1'>Discipline</h2>
                <p className='mt-5 pr-4'>Curate and deliver on time.</p>
            </div>
            <div className='pb-4 text-center'>
                <ShieldCheckIcon className='h-8 text-sumo-red mx-auto' />
                <h2 className='text-2xl mt-1'>Trust</h2>
                <p className='mt-5 pr-4' >Be transparent always and ensure your data is safe.</p>
            </div>
            <div className='pb-4 text-center'>
                <ClipboardCheckIcon className='h-8 text-sumo-blue mx-auto' />
                <h2 className='text-2xl mt-1'>Quality</h2>
                <p className='mt-5 pr-4'>Deliver the best articles every time.</p>
            </div>

        </div>
    </>);
}

export default CoreValues;


