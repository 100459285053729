import React from 'react';

const MissionStatement = () => {
    return ( <>
    <h1 className='text-xl mb-8 tracking-wider text-center md:text-left underline underline-offset-2 decoration-double'>The Sumo Mission</h1>
    <p className='pr-4 text-center lg:text-5xl lg:leading-snug'>To serve our readers with the <span className='underline underline-offset-2 decoration-double decoration-sumo-red'>highest quality curated content</span>  from creators around the Internet and Social Media for <span className='underline underline-offset-2 decoration-double decoration-sumo-blue'>productivity</span>, <span className='underline underline-offset-2 decoration-double decoration-sumo-blue'>wellness</span> and <span className='underline underline-offset-2 decoration-double decoration-sumo-blue'>motivation</span>. 
    </p>
    </> );
}
 
export default MissionStatement;