import React from 'react';

const VisionStatement = () => {
    return ( <>
    <h1 className='text-2xl mb-4 tracking-wider text-center md:text-left underline underline-offset-2 decoration-double'>The Sumo Vision</h1>
    <p className='pr-4 text-center leading-relaxed'>Mindful Sumo aims to be the number one newsletter to inspire, motivate and improve mental fitness to our readers by providing the hand curated articles and media from the best creators on the Internet and Social Media.
    </p>
    </> );
}
 
export default VisionStatement;