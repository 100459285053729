import React from 'react';
import Matt from '../images/matt.jpg'

const AboutMe = () => {
    return ( <>
    <h1 className='text-2xl mb-4 tracking-wider text-center md:text-left underline underline-offset-2 decoration-double'>About Matt</h1>
    <div className='p-4'>
        <img src={Matt} alt='Matt' className='h-40 rounded-full mx-auto grayscale m-10' />
    </div>
    <p className='pr-4 text-center leading-relaxed'>Matt is the author and curator of Mindful Sumo. Matt spends many number of hours reading articles, watching videos out for ways to improve his productivity and mental fitness.  Matt has a passion to try and motivate others
        to be better versions of themselves in all discplines in life.  He has been working as a software engineer for nearly twenty years but for as long as he can remember has been keen to share and inspire others from reading
        out articles on wellbeing as a kid to sharing links on the Internet.
    </p>

    </> );
}
 
export default AboutMe;