import React from 'react';
import Layout from '../components/Layout';
import SumoCardImage from '../components/SumoCard';
import AboutMe from '../sections/AboutMe';
import CoreValues from '../sections/CoreValues';
import MissionStatement from '../sections/MissionStatement';
import TargetMarket from '../sections/TargetMarket';
import VisionStatement from '../sections/VisionStatement';

const About = () => {
    return (<>
        <Layout>
            <div className='container mx-auto'>
                <div className='grid md:grid-cols-4 gap-4 md:gap-6 w-full p-4 font-montserrat justify-center '>
                    <div className='md:col-span-2 bg-white bg-hero-pattern w-full pt-10 pb-10 pl-4 pr-4'><AboutMe /></div>
                    <div className='md:col-span-2 bg-sumo-blue bg-hero-pattern text-white w-full pt-10 pb-10 pl-4 pr-4' ><TargetMarket /></div>
                    <div className='md:row-span-3 md:col-span-4 bg-sumo-white-2 bg-hero-pattern text-black w-full pt-10 pb-10 pl-4 pr-4'><VisionStatement /></div>
                    <div className='md:col-span-4 bg-white bg-hero-pattern w-full pt-10 pb-10 pl-4 pr-4' ><CoreValues /></div>
                    <div className='md:col-span-3 bg-white  w-full pt-10 pb-10 pl-4 pr-4'><MissionStatement /> </div>
                    <div className='md:col-span-1 bg-white  w-full pt-10 pb-10 pl-4 pr-4 im' ><SumoCardImage /> </div>
                </div>
            </div>
        </Layout>

    </>);
}

export default About;