import React from 'react';
import SumoCard from '../images/sumocard.svg'

const SumoCardImage = ({height}) => {
    return (
        <>
            <img src={SumoCard} alt='Sumo' className='h-96 mx-auto'/>
        </>
    )
}

export default SumoCardImage;